@import '~rsuite/styles/variables';
@import 'droppe-theme';

// Queries to match with rsuite
@below-xxxl: ~'only screen and (max-width: 1600px)';
@below-xxl: ~'only screen and (max-width: 1400px)';
@below-xl: ~'only screen and (max-width: 1200px)';
@below-tablet-header-breakpoint: ~'only screen and (max-width: 1105px)'; // TODO: Remove when proper tablet screensize breakpoint designs are implemented
@below-lg: ~'only screen and (max-width: 992px)';
@below-md: ~'only screen and (max-width: 768px)';
@below-sm: ~'only screen and (max-width: 576px)';
@below-xs: ~'only screen and (max-width: 380px)';

// Global spacer for vertical and horizontal rhythm
@spacer: 10px;

// Set rsuite global border-radius
@border-radius: 4px;

// Max-widths
@max-width-medium: 1200px;
@max-width-lg: 1400px;
@order-summary-max-width: 450px;

// Header & footer components
@header-margin-bottom: @spacer * 2;
@header-spacer-x: @spacer * 1.7;
@header-height: 56px;

// Navigation
@nav-bar-default-bg: @B050; // Same as original
@nav-bar-default-active-color: @H700; // Same as original
@nav-bar-default-active-bg: transparent;
@nav-bar-default-font-color: @primary;
@header-background: @B000;

// Typography
@headings-margin-bottom: (@spacer / 2);

// Buttons
@btn-font-weight: 500;

// Picker
@picker-select-menu-item-selected-font-weight: @btn-font-weight;

// Panels
@panel-heading-font-size: @font-size-large;

// Transitions
@transition-delay: 0.3s;

// Z-indexes priority stack
@z-index-mobile-blocker: @z-index-modal - 1; // Lowest priority
@z-index-modal: @z-index-language-modal - 1;
@z-index-language-modal: @z-index-popovers - 1;
@z-index-popovers: @z-index-tooltips - 1;
@z-index-tooltips: @z-index-chaport - 1;
@z-index-chaport: 2147483500; // Default value from external chaport script
@z-index-cookie-consent-backdrop: @z-index-modal + 1;
@z-index-cookie-consent: @z-index-cookie-consent-backdrop + 1; // Highest priority
@z-index-color-palette: 5; // Product card indicators

// Modals
@modal-padding-large: @spacer * 3 @spacer * 4 @spacer * 2 @spacer * 4;
@modal-content-padding: @spacer * 2;
@modal-sm: 500px;
@modal-md: 560px;
@modal-close-btn-size: 18px;
@modal-backdrop-bg: rgba(160, 160, 160, 0.8);
@modal-backdrop-blur: 5px;

// Forms
@form-group-margin-bottom: 25px;

// Panel padding
@panel-block-padding: @spacer * 3;

// Background
@gradient-background: radial-gradient(20.56% 164.54% at 96.74% 3.08%, @teal-mid 21.48%, rgba(245, 214, 143, 0) 100%),
  radial-gradient(29.37% 324.76% at 3.99% 20.96%, @neutral-vanilla 0%, rgba(224, 169, 45, 0.02) 100%), @teal-mid;

// Shadows
@default-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06),
  0 4px 4px rgba(194, 194, 194, 0.12);
@avatar-box-shadow: 0px 4px 4px 0px rgba(194, 194, 194, 0.12), 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
@avatar-box-shadow-alternate: 0 2px 7.5px 0 rgba(@B500, 0.4);
@fade-gradient: linear-gradient(267.77deg, #fff 1.87%, rgba(255, 255, 255, 0) 87.75%);
@product-card-box-shadow: 0 2px 10px 0 rgba(@B600, 0.3);
